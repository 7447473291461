<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="发货点名称" prop="siteName">
        <el-input
          v-model="ruleForm.siteName"
          maxlength="16"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="发货人姓名" prop="userName">
        <el-input
          v-model="ruleForm.userName"
          maxlength="16"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="mobile">
        <el-input
          v-model="ruleForm.mobile"
          maxlength="11"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="所在区域" prop="area">
        <el-cascader
          v-model="ruleForm.area"
          :options="dropDownList_ProvinceCityArea"
          clearable
          filterable
          :props="{
            label: 'name',
            value: 'code',
            children: 'childs',
          }"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="addressDetail">
        <el-input
          v-model="ruleForm.addressDetail"
          maxlength="200"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="关联城市" prop="joinCity">
        <el-select
          v-model="ruleForm.joinCity"
          filterable
          clearable
          multiple
          placeholder="请选择"
        >
          <el-option
            v-for="item in dropDownList_citys"
            :key="item.cityId"
            :label="item.city"
            :value="item.cityId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设为发货点" >
         <el-switch
              v-model="ruleForm.isDeliveryPoint"
              active-text="开"
              inactive-text="关"
            >
            </el-switch>
      </el-form-item>
       <el-form-item label="设为自提点" >
         <el-switch
              v-model="ruleForm.isSelfLiftingPoint"
              active-text="开"
              inactive-text="关"
            >
            </el-switch>
      </el-form-item>
      <el-form-item>
        <el-button @click="btn('cancel')">取消</el-button>
        <el-button type="primary" @click="btn('submit')">确认</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { AddOrEdit, GetDetail } from "@/api/Address";
import { GetProvinceCityArea, GetCity } from "@/api/Dic";

export default {
  props: {
    funcSucc: {
      type: Function,
    },
    editId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ruleForm: {
        siteName: "", //发货点名称
        userName: "", //发货人姓名
        mobile: "", //联系电话
        area: null, //所在区域
        joinCity: [], //关联城市
        addressDetail: "", //详细地址
        isDeliveryPoint:true,
        isSelfLiftingPoint:true,
      },
      dropDownList_ProvinceCityArea: [], //省市区关联数据列表
      dropDownList_citys: [],
      rules: {
        siteName: [
          { required: true, message: "请输入发货点名称", trigger: "blur" },
        ],
        userName: [
          { required: true, message: "请输入发货人姓名", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请填写联系电话", trigger: "blur" },
          {
            pattern: /^1\d{10}$/,
            message: "请填写正确格式的联系电话",
            trigger: "blur",
          },
        ],
        area: [
          {
            required: true,
            message: "请选择所在区域",
            trigger: "blur",
          },
        ],
        joinCity: [
          {
            required: true,
            message: "请选择关联城市",
            trigger: "blur",
          },
        ],
        addressDetail: [
          { required: true, message: "请填写详细地址", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    //pageSize 改变时会触发
    btn(btnType) {
      switch (btnType) {
        case "cancel":
          this.funcSucc();
          console.log("取消");
          break;
        case "submit":
          this.$refs["ruleForm"].validate((valid) => {
            if (!(this.ruleForm.area && this.ruleForm.area.length > 0)) {
              this.$message.error("请选择地区");
              return;
            }
            if (valid) {
              this.loading = true;
              const {
                siteName,
                userName,
                mobile,
                area,
                joinCity,
                addressDetail,
                isDeliveryPoint,
                isSelfLiftingPoint,
              } = this.ruleForm;
              let postData = {
                siteName: siteName,
                userName: userName,
                mobile: mobile,
                provinceId: null,
                cityId: null,
                areaId: null,
                joinCity: joinCity,
                addressDetail: addressDetail,
                isDeliveryPoint:isDeliveryPoint,
                isSelfLiftingPoint:isSelfLiftingPoint,
                merchantId:814,//商家id//需要前端传一下
                addressId: this.editId && this.editId > 0 ? this.editId : 0, //如果是修改行为，就给主键id赋值
              };
              console.log(this.ruleForm);
              if (area) {
                if (area.length > 0) postData.provinceId = area[0];
                if (area.length > 1) postData.cityId = area[1];
                if (area.length > 2) postData.areaId = area[2];
              }
              AddOrEdit(postData)
                .then((res) => {
                  if (res.code == 10000) {
                    this.$message.success(res.msg);
                    this.loading = false;
                    this.funcSucc();
                  } else {
                    this.$message.error(res.msg);
                    this.loading = false;
                  }
                })
                .catch((err) => {
                  this.loading = false;
                });
            } else {
              console.log("登录表单填写信息填写有误!");
            }
          });
          console.log("提交");
          break;
      }
    },
    loadData() {
      //开始加载数据
      GetDetail({ id: this.editId })
        .then((res) => {
          if (res.code == 10000 && res.data) {
            this.ruleForm.siteName = res.data.siteName;
            this.ruleForm.userName = res.data.userName;
            this.ruleForm.mobile = res.data.mobile;
            if (res.data.provinceId && res.data.cityId && res.data.areaId) {
              this.ruleForm.area = [
                res.data.provinceId,
                res.data.cityId,
                res.data.areaId,
              ];
            } else if (res.data.provinceId && res.data.cityId) {
              this.ruleForm.area = [res.data.provinceId, res.data.cityId];
            } else if (res.data.provinceId) {
              this.ruleForm.area = [res.data.provinceId];
            }
            this.ruleForm.joinCity = res.data.joinCity;
            this.ruleForm.addressDetail = res.data.addressDetail;
            this.ruleForm.isDeliveryPoint = res.data.isDeliveryPoint;
            this.ruleForm.isSelfLiftingPoint = res.data.isSelfLiftingPoint;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {});
    },
    InitBaseData() {
      //加载下拉列表数据
      GetProvinceCityArea()
        .then((res) => {
          if (res.code == 10000) {
            this.dropDownList_ProvinceCityArea = res.data;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {});
      //得到市
      GetCity()
        .then((res) => {
          if (res.code == 10000) {
            this.dropDownList_citys = res.data;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {});
    },
  },
  created: function () {
    this.InitBaseData();
    //如果是修改，就加载数据
    if (this.editId && this.editId > 0) {
      this.loadData();
    }
  },
};
</script>

<style scope>
.toolbar {
  background: #f2f2f2;
  padding: 10px;
  margin: 10px 0;
}
.divTb {
  margin-top: 20px;
}
.el-pagination {
  text-align: center;
  margin-top: 13px;
}
.el-input {
  width: 300px;
  margin-right: 10px;
}
.right {
  float: right;
  padding-right: 50px;
  padding-bottom: 10px;
}
</style>